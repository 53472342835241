<template>
  <div class="reportPage">
    <!-- <h2 class="mt-5">{{ $t(config.crudHeader) }}</h2> -->
    <v-container fluid>
      <v-btn color="danger" hide-details small id="print" @click="print">{{
        $t("Print")
      }}</v-btn>
      <table>
        <thead>
          <tr>
            <th colspan="8" style="background:#1F4E78;text-align:center;">
              <h2 style="color:#fff !important">
              تقرير عدد المشتركين بالنسبة للمناطق من {{ filters.from_date }} إلى {{ filters.to_date }}
              </h2>
            </th>
          </tr>
          <tr>
            <th style="background:#BDD7EE !important;color:#000;text-align:center;border:1px solid #000 !important;">#</th>
            <th style="background:#BDD7EE !important;color:#000;text-align:center;border:1px solid #000 !important;">المنطقة</th>
            <th style="background:#BDD7EE !important;color:#000;text-align:center;border:1px solid #000 !important;">المندوب</th>
            <th style="background:#BDD7EE !important;color:#000;text-align:center;border:1px solid #000 !important;">صباحي</th>
            <th style="background:#BDD7EE !important;color:#000;text-align:center;border:1px solid #000 !important;">مسائي</th>
            <th style="background:#BDD7EE !important;color:#000;text-align:center;border:1px solid #000 !important;">الإجمالي</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in items" :key="index">
            <td style="text-align:center;border:1px solid #000 !important;">{{ row.id }}</td>          
            <td style="text-align:center;border:1px solid #000 !important;">{{ row.city_name }}</td>          
            <td style="text-align:center;border:1px solid #000 !important;">{{ row.driver_name }}</td>          
            <td style="text-align:center;border:1px solid #000 !important;">{{ row.delivery_all_count_for_morning_period }}</td>          
            <td style="text-align:center;border:1px solid #000 !important;">{{ row.delivery_all_count_for_evening_period }}</td>          
            <td style="text-align:center;border:1px solid #000 !important;">{{ row.delivery_all_count }}</td>          
          </tr>
        </tbody>
      </table>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {
      items: [],
      filters: {
        from_date: '',
        to_date: '',
         delivery_all_count_value: '',
        operator: '',
        city_ids: [],
      },
      config: {
        crudHeader: "AreasCustomers",
        crudApi: "areasCustomers",
      },
    };
  },
  methods: {
    initData() {
      let dataTable= localStorage.getItem('areasCustomersItems');
      let filters= localStorage.getItem('areasCustomersFilters');
      let filters_finish= JSON.parse(filters);
      filters_finish.from_date= moment(filters_finish.from_date).format("YYYY-MM-DD");
      filters_finish.to_date= moment(filters_finish.to_date).format("YYYY-MM-DD");
      this.filters= filters_finish;

      if(dataTable){
        let tableItems= JSON.parse(dataTable);
        this.items = tableItems;
      }
    },
    print() {
      window.print();
    },
  },
  created() {
    let app_title = document.getElementById("app_title");
    app_title.innerText = this.$t(this.config.crudHeader);
    this.initData();
  },
};
</script>